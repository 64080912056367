<template>
  <v-card color="secondary" dark>
    <v-card-title>
      {{ question }}
    </v-card-title>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="emitAnswer"
      >
        Aceptar
      </v-btn>
      <!-- <v-btn
        color="secondary"
        @click="dialog = false"
      >
        Cancelar
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    question: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      confirmation: false
    }
  },
  methods: {
    emitAnswer() {
      this.confirmation = true
      this.$emit('answer', this.confirmation)
    }
  }
}
</script>

<style>

</style>